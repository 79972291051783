import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import Icon, { ChevronLeft } from "./icon";

const Return = ({ linkPage, linkTo }) => (
    <Link
        className="inline-block btn-link pr-2"
        title={"Return to '" + linkPage + "' page"}
        to={linkTo}
    >
        <Icon name={ChevronLeft} width="1.5rem" />
    </Link>
);
Return.propTypes = {
    linkPage: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired
};

export default {
    Return
};
