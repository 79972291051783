import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import Layout from "../../components/layout";
import Loading from "../../components/loading";
import Navigation from "../../components/navigation";
import ConfirmBox from "../../components/confirmBox";
import { ErrMsg } from "../../components/message";

import { CurrentUserContext } from "../../providers/auth";
import Services, {
    isUnauthorizedError,
    isForbiddenError
} from "../../services";
import { formatDate, formatDateStr, timeFormats } from "../../utils/time";
import { lamppostDisplayName } from "../../utils/lamppost";
import UserUtil from "../../utils/user";
import Links from "../../utils/links";

const DataApproveDetailsPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Validation}
            seoTitle="Data Approval Details"
            permissionValidator={UserUtil.hasSysAdminPrivileges}
            forceCheckSessionCount={checkCount}
        >
            <DataApproveDetails forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default DataApproveDetailsPage;

const DataApproveDetails = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;

    const [month, setMonth] = useQueryParam("month", StringParam);
    const [lamppostId, setLamppostId] = useQueryParam(
        "lamppostId",
        StringParam
    );

    const [timeRange, setTimeRange] = useState(null);
    const [lamppost, setLamppost] = useState(null);
    const [loading, setLoading] = useState(false);
    const [datasets, setDatasets] = useState(null);
    const [datasetErr, setDatasetErr] = useState("");

    const handleAuthError = (err) => {
        if (isUnauthorizedError(err) || isForbiddenError(err)) {
            forceCheckSession();
        }
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setLoading(true);
        window.currentUser = currentUser;
        lamppostService
            .getLampposts()
            .then((d) => {
                const l = (d.lampposts || []).find(
                    (l) => l.lamppost_id == lamppostId
                );
                setLamppost(l);
            })
            .catch((err) => {
                handleAuthError(err);
                console.error(err);
            });
        lamppostService
            .getMetricDataset(lamppostId, month)
            .then((d) => setTimeRange({ from: d.from, to: d.to }))
            .catch((err) => {
                handleAuthError(err);
                console.error(err);
            });
        lamppostService
            .getValidationsToApprove(lamppostId, month)
            .then((d) => setDatasets(d.validations))
            .catch((err) => {
                handleAuthError(err);
                setDatasetErr(err.message || "Dataset is not found.");
            })
            .finally(() => setLoading(false));
    }, [currentUser]);

    const ContentView = () => (
        <>
            <div className="text-base">
                <span className="label">Lamppost</span>
                {lamppost && (
                    <span>
                        {lamppostDisplayName(lamppost)} in{" "}
                        {lamppost.district_en}
                    </span>
                )}
            </div>
            <div className="mt-2 text-base">
                <span className="label">Date</span>{" "}
                {timeRange && (
                    <span>
                        {formatDate(timeRange.from, timeFormats.day)} to{" "}
                        {formatDate(timeRange.to, timeFormats.day)}
                    </span>
                )}
            </div>
            {datasets &&
                (datasets.length !== 0 ? (
                    <div className="text-xl mt-4 pb-2">
                        Unapproved Validations
                    </div>
                ) : (
                    <div className="gray text-center my-12">
                        No correction is made for this month.
                    </div>
                ))}
            {datasets && lamppost && (
                <ApprovalView
                    datasets={datasets}
                    lamppost={lamppost}
                    lamppostService={lamppostService}
                    lamppostId={lamppostId}
                    month={month}
                    onError={handleAuthError}
                />
            )}
            <ErrMsg msg={datasetErr} />
        </>
    );

    return (
        <>
            <div className="title-h1 flex items-center">
                <Navigation.Return
                    linkTo="/validation"
                    linkPage="Data Approval Overview"
                />
                Data Approval
            </div>
            {loading ? <Loading /> : <ContentView />}
        </>
    );
};

DataApproveDetails.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const ApprovalView = ({
    datasets,
    lamppost,
    lamppostService,
    lamppostId,
    month,
    onError
}) => {
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [confirmReject, setConfirmReject] = useState(false);
    const [actionErr, setActionErr] = useState("");
    const [rejectReason, setRejectReason] = useState("");

    const approveDataset = () => {
        lamppostService
            .approveMetricDataset(lamppostId, month)
            .then(() => navigate("/validation"))
            .catch((err) => {
                setActionErr(err.message || "Cannot approve dataset.");
                setConfirmApprove(false);
                onError(err);
            });
    };

    const rejectDataset = () => {
        lamppostService
            .rejectMetricDataset(lamppostId, month, rejectReason)
            .then(() => navigate("/validation"))
            .catch((err) => {
                setActionErr(err.message || "Cannot reject dataset.");
                setConfirmReject(false);
                onError(err);
            });
    };

    return (
        <>
            {datasets.length !== 0 && (
                <DatasetsTable datasets={datasets} lamppost={lamppost} />
            )}
            <div className="mt-8 text-center">
                <button
                    className="btn-primary"
                    onClick={() => {
                        setActionErr("");
                        setConfirmApprove(true);
                    }}
                >
                    Approve
                </button>
                <button
                    className="ml-6 btn-delete"
                    onClick={() => {
                        setActionErr("");
                        setConfirmReject(true);
                    }}
                >
                    Reject
                </button>
                <ErrMsg msg={actionErr} />
            </div>

            {confirmApprove && (
                <ConfirmBox
                    action={approveDataset}
                    cancelAction={() => setConfirmApprove(false)}
                >
                    Are you sure to approve the dataset?
                </ConfirmBox>
            )}
            {confirmReject && (
                <ConfirmBox
                    action={rejectDataset}
                    cancelAction={() => {
                        setRejectReason("");
                        setConfirmReject(false);
                    }}
                >
                    Are you sure to{" "}
                    <span className="text-red-700">reject the dataset</span>?
                    <div className="pt-4 px-2">
                        <textarea
                            className="input"
                            type="text"
                            placeholder="Justification (Optional)"
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)}
                        ></textarea>
                    </div>
                </ConfirmBox>
            )}
        </>
    );
};

const DatasetsTable = ({ datasets, lamppost }) => (
    <table className="w-full admin-table">
        <thead>
            <tr>
                <th style={{ width: "20%" }}>Date</th>
                <th style={{ width: "30%" }}>Validated By</th>
                <th style={{ width: "25%" }}>Last Validated (HKT)</th>
                <th style={{ width: "25%" }}></th>
            </tr>
        </thead>
        <tbody>
            {datasets.map((d, idx) => (
                <tr key={idx}>
                    <td>
                        {formatDateStr(d.date, "YYYYMMDD", timeFormats.day)}
                    </td>
                    <td>
                        {d.validated_by.map((u, idx2) => {
                            return (
                                <span key={idx2}>
                                    {idx2 !== 0 && ", "}
                                    <span className="inline-block">{u}</span>
                                </span>
                            );
                        })}
                    </td>
                    <td>
                        {formatDate(d.last_validated_at, timeFormats.dayTime)}
                    </td>
                    <td>
                        <a
                            className="btn-secondary px-3"
                            href={`/validation/validate?date=${
                                d.date
                            }&lamppostId=${
                                lamppost.lamppost_id
                            }&valMonth=${formatDateStr(
                                d.date,
                                "YYYYMMDD",
                                timeFormats.YYYYMM
                            )}&valLp=${lamppost.lamppost_id}`}
                            target="_blank"
                        >
                            View
                        </a>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);
